import DOMPurify from 'dompurify';
import React from 'react';
import { toTitleCase } from '../../utils/text';
import './group-event-agenda-card.scss';

type Props = {
  eventType: string;
  isExample?: boolean;
  className?: string;
  description?: string;
};

const GroupEventAgendaCard = ({
  eventType,
  isExample,
  className,
  description,
}: Props) => {
  const HtmlContent = (htmlContent: string) => {
    // Sanitize the HTML content before setting it
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);

    return (
      <div>
        {/* Using dangerouslySetInnerHTML to inject sanitized HTML */}
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      </div>
    );
  };
  return (
    <div
      className={`group-event-agenda-card__card ${className ? className : ''}`}
    >
      <h2 className={'group-event-agenda-card__body-title'}>
        {isExample ? 'Example ' : ''}
        {toTitleCase(eventType)} Agenda
      </h2>
      <span className="group-event-agenda__description">
        {HtmlContent(description ? description : '')}
      </span>
      <div className="group-event-agenda-card__card-row">
        <span className="group-event-agenda-card__card-row-1">
          👋OPENING: Your moderator will welcome everyone to the session and
          give a quick overview of how the session will be run before handing
          over to you.
        </span>
        <span className="group-event-agenda-card__card-row-2">5 mins</span>
      </div>
      <div className="group-event-agenda-card__card-row">
        <span className="group-event-agenda-card__card-row-1">
          🤝 INTRO: You can introduce yourself to the group and ask the
          participants to introduce themselves in the chatbox and how much they
          know about the topic.{' '}
        </span>
        <span className="group-event-agenda-card__card-row-2"> 5 mins</span>
      </div>
      <div className="group-event-agenda-card__card-row">
        <span className="group-event-agenda-card__card-row-1">
          🎤 PRESENTATION: Here, you can talk about the topic, best practices,
          recommendations, and an example or two if relevant
        </span>
        <span className="group-event-agenda-card__card-row-2">25 mins</span>
      </div>
      <div className="group-event-agenda-card__card-row">
        <span className="group-event-agenda-card__card-row-1">
          💬 Q{encodeURI('&')}A: You can open up the floor to questions from the
          participants and those submitted beforehand, which you can find in
          your speaker briefing.
        </span>
        <span className="group-event-agenda-card__card-row-2">25 mins</span>
      </div>
      <div className="group-event-agenda-card-alert">
        <span className="group-event-agenda-card__card-row-1">
          Make sure you log in 5 minutes before your session is due to start so
          that the moderator can help you to set up your presentation and run
          through the agenda with you.
        </span>
      </div>
    </div>
  );
};

export default GroupEventAgendaCard;
