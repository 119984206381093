import React, { useEffect, useState } from 'react';
import { IconButton, Switch, TextField, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import DatePicker from 'react-datepicker';
import {
  CustomFormControlLabel,
  CustomFormLabel,
  CustomTypography,
} from '../../styling/generalStyling';
import HelpIcon from '@mui/icons-material/Help';
import useWindowSize from '../../hooks/useWindowSize';
import { DashboardFilledButton } from '../../styling/buttons';
interface AvailabilityType {
  days: number[];
  time: number;
  end_hour: number;
  start_hour: number;
  end_minute: number;
  start_minute: number;
  ongoing_mentoring: boolean;
  one_off_mentoring: boolean;
}

type Props = {
  setAvailability: (data: AvailabilityType) => void;
  availability: AvailabilityType;
  setModalIsOpen: (value: boolean) => void;
};

const Availability = ({
  setAvailability,
  availability,
  setModalIsOpen,
}: Props) => {
  const [selectedDays, setSelectedDays] = useState(availability.days);
  const [time, setTime] = useState(availability.time);
  const days: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const sendData = () => {
    const newAvailability: AvailabilityType = {
      days: selectedDays,
      time: time,
      end_hour: endDateTime.getHours(),
      start_hour: startDateTime.getHours(),
      end_minute: endDateTime.getMinutes(),
      start_minute: startDateTime.getMinutes(),
      one_off_mentoring: mentoringPreferenceOneOff,
      ongoing_mentoring: mentoringPreferenceOngoing,
    };
    newAvailability.start_minute = startDateTime.getMinutes();
    setAvailability(newAvailability);
    setModalIsOpen(true);
  };
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  const today = new Date();
  const inititalStartDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    availability.start_hour,
    availability.start_minute
  );
  const inititialEndDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    availability.end_hour,
    availability.end_minute
  );
  const [startDateTime, setStartDateTime] = useState<any>(
    inititalStartDateTime
  );
  const [mentoringPreferenceOneOff, setMentoringPreferenceOneOff] =
    useState(true);
  const [mentoringPreferenceOngoing, setMentoringPreferenceOngoing] =
    useState(true);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [endDateTime, setEndDateTime] = useState<any>(inititialEndDateTime);
  return (
    <div className="mentoring-preferences__container">
      <div className="mentoring-preferences-title">
        Number of hours you can volunteer
      </div>
      <FormControl>
        <CustomFormLabel id="mentoring-radio-buttons-group-label">
          This lets us know how many requests to send you, but you can mentor as
          much or as little as you can in a given week.
        </CustomFormLabel>
        <RadioGroup
          aria-labelledby="mentoring-radio-buttons-group-label"
          name="radio-buttons-group"
          value={time}
          sx={{
            '& .Mui-checked': {
              color: '#7FFFD4',
            },
          }}
        >
          <CustomFormControlLabel
            value={60}
            onClick={() => setTime(60)}
            control={<Radio />}
            label={<CustomTypography> 1 hour per week</CustomTypography>}
          />
          <CustomFormControlLabel
            value={120}
            onClick={() => setTime(120)}
            control={<Radio />}
            label={<CustomTypography> 2 hours per week</CustomTypography>}
          />
          <CustomFormControlLabel
            value={180}
            onClick={() => setTime(180)}
            control={<Radio />}
            label={<CustomTypography> 3 hours per week</CustomTypography>}
          />
          <CustomFormControlLabel
            value={240}
            onClick={() => setTime(240)}
            control={<Radio />}
            label={<CustomTypography> 4 hours per week</CustomTypography>}
          />
          <div className="mentoring-custom-hours-input">
            <CustomFormControlLabel
              value={time > 240 ? time : 'null'}
              onClick={() => setTime(300)}
              control={<Radio />}
              disabled={time < 1}
              label={<CustomTypography>Custom </CustomTypography>}
            />
            <TextField
              sx={{
                marginLeft: '5px',
                width: '38px',
                '& .MuiInputBase-formControl': {
                  fontFamily: 'Gilroy',
                  fontSize: '15px',
                  justifyContent: 'center',
                },
              }}
              type="number"
              inputProps={{ min: 1, max: 1000 }}
              value={time > 240 ? time / 60 : null}
              onChange={(event) => setTime(parseInt(event.target.value) * 60)}
              id="standard-basic"
              label=""
              variant="standard"
            />
            <CustomTypography> hours per week</CustomTypography>
          </div>
        </RadioGroup>
      </FormControl>

      <div className="mentoring-preferences-title"> Days you can volunteer</div>
      <FormControl>
        <CustomFormLabel id="gender-radio-buttons-group-label">
          Select the days in week you are happy to volunteer in. We will not
          send you requests beyond these times.
        </CustomFormLabel>
        <FormGroup>
          {days.map((day, index) => (
            <CustomFormControlLabel
              control={
                <Checkbox
                  checked={
                    selectedDays.find(
                      (selectedDay) => selectedDay === index
                    ) !== undefined
                  }
                  onChange={() => {
                    if (selectedDays.indexOf(index) !== -1)
                      setSelectedDays(
                        selectedDays.filter(
                          (selectedDay) => selectedDay !== index
                        )
                      );
                    else
                      setSelectedDays((selectedDays) => [
                        ...selectedDays,
                        index,
                      ]);
                  }}
                  name={day}
                  color="primary"
                />
              }
              label={<CustomTypography> {day}</CustomTypography>}
            />
          ))}
        </FormGroup>
      </FormControl>

      <div className="mentoring-preferences-title">
        {' '}
        Select the time range you are happy to accept mentoring requests between
      </div>
      <div className="datePicker__container">
        <div className="datePicker-time">
          <CustomTypography>Start Time</CustomTypography>
          <DatePicker
            className="datePicker"
            selected={startDateTime}
            onChange={(date) => setStartDateTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
          />
        </div>
        <div className="datePicker-time">
          <CustomTypography>End Time</CustomTypography>
          <DatePicker
            className="datePicker"
            selected={endDateTime}
            onChange={(date) => setEndDateTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
          />
        </div>
      </div>
      <div>
        <div className="mentoring-preferences-title">
          Type of mentoring you wish to do
        </div>
        <FormControl>
          <FormGroup>
            <CustomFormControlLabel
              control={
                <Switch
                  onChange={() => {
                    if (!mentoringPreferenceOneOff === false) {
                      if (mentoringPreferenceOngoing) {
                        setMentoringPreferenceOneOff(
                          !mentoringPreferenceOneOff
                        );
                      }
                    } else {
                      setMentoringPreferenceOneOff(!mentoringPreferenceOneOff);
                    }
                  }}
                  checked={mentoringPreferenceOneOff}
                />
              }
              label={
                <CustomTypography>
                  One-off sessions{' '}
                  <Tooltip
                    open={showTooltip1}
                    onClick={() => setShowTooltip1(true)}
                    onClose={() => setShowTooltip1(false)}
                    leaveTouchDelay={isMobile ? 5000 : 1500}
                    placement={isMobile ? 'top-start' : 'right'}
                    title="You only need to commit to one 60 minute session with a particular mentee to give ideas, feedback, and answer questions. You can always meet again after the first session if you both want to, and you can meet with as many different mentees as you want."
                  >
                    <IconButton sx={{ fontSize: 'small' }}>
                      <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                    </IconButton>
                  </Tooltip>
                </CustomTypography>
              }
            />
            <CustomFormControlLabel
              control={
                <Switch
                  onChange={() => {
                    if (!mentoringPreferenceOngoing === false) {
                      if (mentoringPreferenceOneOff) {
                        setMentoringPreferenceOngoing(
                          !mentoringPreferenceOngoing
                        );
                      }
                    } else {
                      setMentoringPreferenceOngoing(
                        !mentoringPreferenceOngoing
                      );
                    }
                  }}
                  checked={mentoringPreferenceOngoing}
                />
              }
              label={
                <CustomTypography>
                  Ongoing mentoring{' '}
                  <Tooltip
                    open={showTooltip2}
                    onClick={() => setShowTooltip2(true)}
                    onClose={() => setShowTooltip2(false)}
                    leaveTouchDelay={isMobile ? 5000 : 1500}
                    placement={isMobile ? 'top-start' : 'right'}
                    title="You are interested in having an ongoing relationship with a particular mentee to support them throughout their business journey. There is no specific requirement for how often you’d meet, but we suggest you plan to meet with them around once a month."
                  >
                    <IconButton sx={{ fontSize: 'small' }}>
                      <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                    </IconButton>
                  </Tooltip>
                </CustomTypography>
              }
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className="profile-create__button-container">
        <DashboardFilledButton
          sx={{
            marginTop: '50px',
            width: '200px',
          }}
          variant="contained"
          disabled={time < 1 || selectedDays.length === 0}
          onClick={sendData}
          className="profile-create__next-button"
        >
          Done
        </DashboardFilledButton>
      </div>
    </div>
  );
};

export default Availability;
