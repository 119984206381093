import React, { useState, useEffect, memo } from 'react';
import Cookie from 'js-cookie';
import FullScreenLoading from './components/FullScreenLoading';
import { UserContext } from './context/UserProfile';
import { SystemToggleContext } from './context/SystemToggles';
import { TaxonomyContext } from './context/Taxonomy';
import useGetSystemToggles from './hooks/useGetSystemToggles';
import useGetTaxonomy from './apiHooks/useGetTaxonomy';
import useUpdateTimezone from './apiHooks/user/updateUserTimezone';
import useFetchProfileData from './apiHooks/auth/useFetchProfileData';
import { fetchAllProfileDataAndUpdateContext } from './utils/user';
import {
  UserProfileContext,
  UserLocationContext,
  PartnerContext,
  VolunteerProfileContext,
} from './types';
import useSelectProfileData from './hooks/useSelectProfileData';
import {
  redirectToF4S,
  useRedirectToPartnerWebsite,
} from './utils/partnerFunctionality';
import { useFetchPremiumPartnerbySubdomain } from './apiCalls/user';
const AppProvider = ({ children }: { children: React.ReactNode }) => {
  redirectToF4S();
  useRedirectToPartnerWebsite();
  useFetchPremiumPartnerbySubdomain();
  const { fetchAllProfileData } = useFetchProfileData();
  const {
    userProfile,
    volunteerProfile,
    hasFinishedFetchingProfileAndLocation,
  } = useSelectProfileData({ autoFetch: true });
  const [, setVolunteerProfileContext] = useState<VolunteerProfileContext>({
    hasFinishedVolunteerProfileFetch: false,
  });
  const [partnerContext, setPartnerContext] = useState<PartnerContext>({
    hasFinishedPartnerFetch: false,
  });
  const { partner } = partnerContext || {};

  const [userLocationContext, setUserLocationContext] =
    useState<UserLocationContext>({ hasFinishedLocationFetch: false });
  const { userLocation, hasFinishedLocationFetch } = userLocationContext || {};
  const [, setUserProfileContext] = useState<UserProfileContext>({
    hasFinishedUserProfileFetch: false,
  });
  const { toggles, setToggles, hasFinishedLoadingToggles } =
    useGetSystemToggles({ isBetaUser: userProfile?.is_beta_user });

  const autoDetectTimezone = toggles['TIMEZONE_AUTO_DETECT'];

  const hasFinishedLoadingAllUserData =
    hasFinishedFetchingProfileAndLocation && hasFinishedLocationFetch;

  const { updateTimezoneAndGetLocationData } = useUpdateTimezone();

  const accessToken = Cookie.get('accessTokenDB');
  useEffect(() => {
    if (!hasFinishedLoadingToggles) return;
    (async () => {
      // running this on every page means that refreshAuthLogic & redirectProtecedRoutesToLogin are used to auth-protect the correct pages
      fetchAllProfileDataAndUpdateContext({
        fetchAllProfileData,
        setUserProfileContext,
        setVolunteerProfileContext,
        setUserLocationContext,
        setPartnerContext,
        autoDetectTimezone: autoDetectTimezone
          ? updateTimezoneAndGetLocationData
          : null,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, autoDetectTimezone, hasFinishedLoadingToggles]);

  const {
    taxonomyData,
    taxonomyById,
    taxonomyDataFormatted,
    hasFinishedLoadingTaxonomy,
    taxonomyError,
    refetchTaxonomy,
  } = useGetTaxonomy();

  return (
    <UserContext.Provider
      value={{
        volunteerProfile,
        userLocation,
        userProfile,
        partner,
        setVolunteerProfileContext,
        setUserLocationContext,
        setUserProfileContext,
        setPartnerContext,
        hasFinishedFetchingProfileAndLocation,
      }}
    >
      <TaxonomyContext.Provider
        value={{
          taxonomyData,
          taxonomyById,
          taxonomyDataFormatted,
          hasFinishedLoadingTaxonomy,
          taxonomyError: taxonomyError || undefined,
          refetchTaxonomy,
        }}
      >
        <SystemToggleContext.Provider
          value={{ toggles, setToggles, hasFinishedLoadingToggles }}
        >
          <>
            {!hasFinishedLoadingAllUserData ? <FullScreenLoading /> : children}
          </>
        </SystemToggleContext.Provider>
      </TaxonomyContext.Provider>
    </UserContext.Provider>
  );
};

export default memo(AppProvider);
