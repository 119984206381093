import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { FormLabel, Select } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import { InputLabel } from '@mui/material';
export const CustomTextField = styled(TextField)({
  fontSize: '16px',
  color: '#414141',
  marginTop: '10px',
  marginRight: '10px',
  marginBottom: '10px',
  fontFamily: 'Gilroy',
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Gilroy',
  },
});

export const CustomFormLabel = styled(FormLabel)({
  fontSize: '14px',
  marginBottom: '5px',
  fontWeight: '500',
  lineHeight: '1.3',
  color: '#a3a3a3',
  fontFamily: 'Gilroy',
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -4,
    top: -5,
    height: '18px',
  },
}));

export const CustomTypography = styled(Typography)({
  fontSize: '16px',
  lineHeight: '1.3',
  color: '#414141',
  fontFamily: 'Gilroy',
});

export const CustomTypographyPermission = styled(Typography)({
  fontSize: '16px',
  lineHeight: '1.3',
  fontWeight: '500',
  fontFamily: 'Gilroy',
});
export const CustomBriefingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  lineHeight: '1.3',
  fontFamily: 'Gilroy',
  color: theme.palette.primary.main,
}));

export const CustomMasterclassTypography = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  fontFamily: 'Gilroy',
}));

export const CustomOrganisationNameTypography = styled(Typography)(
  ({ theme }) => ({
    fontSize: '26px',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '1.3',
    width: 'fit-content',
    color: theme.palette.primary.main,
    fontFamily: 'Gilroy',
  })
);

export const CheckboxCustomTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.3',
  letterSpacing: '0.28px',
  textAlign: 'left',
  color: '#333',
  fontFamily: 'Gilroy',
  marginLeft: '-12px',
});

export const CustomHeaderTypography = styled(Typography)({
  fontSize: '30px',
  marginBottom: '30px',
  fontWeight: 'bold',
  lineHeight: '1.1',
  textAlign: 'left',
  color: '#0f0f22',
  fontFamily: 'Gilroy',
});

export const CustomFormControlLabel = styled(FormControlLabel)({
  margin: '0 0 0 0px',
});

export const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

export const TimeSelect = styled(Select)({
  width: '240px',
  height: '46px',
  margin: '4px 0 0',
  borderRadius: ' 8px',
  border: 'solid 1.2px #333',
  backgroundColor: '#fff',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  fontSize: '14px',
  color: '#414141',
  textAlign: 'left',
});

export const NoBorderTextField = styled(TextField)({
  fontFamily: 'Gilroy',
  marginLeft: '25px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
  letterSpacing: '0.18px',
  width: 'auto',
  height: '40px',
  textAlign: 'center',
  border: 'none',
  '& .MuiInputBase-input.Mui-disabled': {
    border: 'none',
    fontFamily: 'Gilroy',
    WebkitTextFillColor: '#000000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
});
export const CustomTextFieldActionPlanName = styled(TextField)({
  fontSize: '16px',
  marginRight: '10px',
  fontFamily: 'Gilroy',
  borderColor: '#333',
  '& .MuiInputLabel-root': {
    fontFamily: 'Gilroy',
  },
  '& .MuiInput-root': {
    fontFamily: 'Gilroy',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#3333',
    fontFamily: 'Gilroy',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#333',
    fontFamily: 'Gilroy',
  },
});
export const CustomTimeLabel = styled(InputLabel)({
  fontSize: '14px',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  textAlign: 'left',
});

export const NoBorderTextFieldMobile = styled(TextField)({
  fontFamily: 'Gilroy',
  marginLeft: '25px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
  letterSpacing: '0.18px',
  width: '100%',
  textAlign: 'center',
  border: 'none',
  '& .MuiInputBase-input.Mui-disabled': {
    border: 'none',
    fontFamily: 'Gilroy',
    WebkitTextFillColor: '#000000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
});
