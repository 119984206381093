import { AxiosPromise, AxiosResponse } from 'axios';
import apiInstance from './index';

export type SearchMessagesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: any;
};

export interface MessageUsersProps {
  name?: string;
  limit?: number;
}
export const getMessageUsers = ({
  name,
  limit,
}: MessageUsersProps): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(
    name && name.length > 0
      ? `api/messagecentre/messageusers/search?name=${name}&has_messages=Y`
      : `api/messagecentre/messageusers/search?has_messages=Y&limit=${
          limit ? 10 * limit : 10
        }`,
    {
      method: 'GET',
    }
  );

export const getAvailableUsers = ({
  name,
  limit,
}: MessageUsersProps): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(
    name && name.length > 0
      ? `api/messagecentre/messageusers/search?name=${name}`
      : `api/messagecentre/messageusers/search?has_messages=A&limit=${
          limit ? 10 * limit : 1000
        }`,
    {
      method: 'GET',
    }
  );

export interface SearchMessageUsers {
  name: string;
}
export const searchMessageUsers = ({
  name,
}: SearchMessageUsers): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(`api/messagecentre/messageusers/search?name=${name}`, {
    method: 'GET',
  });

export interface BlockUserProps {
  organisationId: number;
  isBlocked: boolean;
}
export const blockUser = ({
  organisationId,
  isBlocked,
}: BlockUserProps): AxiosPromise<any> =>
  apiInstance('api/messagecentre/blockorganisations/manage/', {
    method: 'POST',
    data: {
      organisation_id: organisationId,
      is_blocked: isBlocked,
    },
  });

export interface SearchMessagesProps {
  organisationId: number;
  limit?: number;
}

export const searchMessages = ({
  organisationId,
  limit,
}: SearchMessagesProps): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(
    limit
      ? `api/messagecentre/messages/search?organisation_id=${organisationId}&limit=${
          limit * 10
        }`
      : `api/messagecentre/messages/search?organisation_id=${organisationId}&limit=6`,
    {
      method: 'GET',
    }
  );

export interface SendMessageProps {
  organisationId: number;
  text: string;
}

export const sendMessage = ({
  text,
  organisationId,
}: SendMessageProps): AxiosPromise<any> =>
  apiInstance('api/messagecentre/messages/sendmessage/', {
    method: 'POST',
    data: {
      organisation_id: organisationId,
      message: text,
    },
  });

export const startMessage = async (
  organisationId: number
): Promise<AxiosResponse<any> | Error> => {
  try {
    const response = await apiInstance(
      'api/messagecentre/messageusers/manage/',
      {
        method: 'POST',
        data: {
          organisation_id: organisationId,
        },
      }
    );
    return response;
  } catch (error: any) {
    console.error('Error starting message:', error);
    return error;
  }
};

export const getUnreadMessagesCount = (): AxiosPromise<any> =>
  apiInstance('api/messagecentre/unreadmessages', {
    method: 'GET',
  });

export interface ReadMessagesProps {
  organisationId: number;
}

export const readMessages = ({
  organisationId,
}: ReadMessagesProps): AxiosPromise<any> =>
  apiInstance('api/messagecentre/messages/readmessages/', {
    method: 'POST',
    data: {
      organisation_id: organisationId,
    },
  });
