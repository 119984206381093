import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import type { RootState } from '../../store';
import {
  getMessageUsers,
  searchMessages,
  SearchMessagesProps,
  sendMessage,
  SendMessageProps,
  BlockUserProps,
  blockUser,
  MessageUsersProps,
  getUnreadMessagesCount,
  getAvailableUsers,
  ReadMessagesProps,
  readMessages,
} from '../../api/messagingCentre';

/* --- SLICE --- */

interface MessagesState {
  messages: any;
  messagesCount: number;
  hasNextMessages: boolean;
  messageUsers: any;
  hasNextUsers: boolean;
  availableUsers: any;
  hasNextAvailableUsers: boolean;
  unreadCount: number;
  loadingMessageUsers: 'init' | 'pending' | 'fulfilled' | 'rejected';
  loadingAvailableUsers: 'init' | 'pending' | 'fulfilled' | 'rejected';
}

const initialState: MessagesState = {
  messages: [],
  messagesCount: 0,
  hasNextMessages: true,
  messageUsers: [],
  hasNextUsers: true,
  loadingMessageUsers: 'init',
  availableUsers: [],
  hasNextAvailableUsers: true,
  loadingAvailableUsers: 'init',
  unreadCount: 0,
};

const messagingCentreSlice = createSlice({
  name: 'messagingCentre',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setMessageUsers: (state, action) => {
      state.messageUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // messageUsers
      .addCase(messageUsersAction.pending, (state, action) => {
        state.loadingMessageUsers = action.meta.requestStatus;
      })
      .addCase(messageUsersAction.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { requestStatus } = meta;
        const { results, next } = payload;

        state.messageUsers = results;
        state.loadingMessageUsers = requestStatus;
        state.hasNextUsers = !!next;
      })
      // availableUsers
      .addCase(availableUsersAction.pending, (state, action) => {
        state.loadingAvailableUsers = action.meta.requestStatus;
      })
      .addCase(availableUsersAction.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { requestStatus } = meta;
        const { results, next } = payload;

        state.availableUsers = results;
        state.hasNextAvailableUsers = !!next;
        state.loadingAvailableUsers = requestStatus;
      })
      // searchMessages
      .addCase(searchMessagesAction.fulfilled, (state, action) => {
        const { payload } = action;
        const { results, next } = payload;

        state.messages = [];
        const orderedMessages = _.chain(results)
          .groupBy((message) => moment(message?.created).format('DD MMMM YYYY'))
          .map((messages, date) => ({ date, messages }))
          .orderBy((group) => new Date(group.date), ['desc'])
          .value();
        state.messages = orderedMessages;
        state.hasNextMessages = !!next;
        state.messagesCount = results?.length || 0;
      })
      .addCase(getUnreadMessagesCountAction.fulfilled, (state, action) => {
        const { payload } = action;
        const { total } = payload;
        state.unreadCount = total;
      });
  },
});

/* --- SELECTORS --- */
// messageUsers
export const selectMessageUsers = (state: RootState) =>
  state.messagingCentre.messageUsers;
export const loadingMessageUsers = (state: RootState) =>
  state.messagingCentre.loadingMessageUsers === 'init';
export const selectHasNextUsers = (state: RootState) =>
  state.messagingCentre.hasNextUsers;

export const selectAvailableUsers = (state: RootState) =>
  state.messagingCentre.availableUsers;
export const selectHasNextAvailableUsers = (state: RootState) =>
  state.messagingCentre.hasNextAvailableUsers;
export const loadingAvailableUsers = (state: RootState) =>
  state.messagingCentre.loadingAvailableUsers === 'init' ||
  state.messagingCentre.loadingAvailableUsers === 'pending';

// searchMessages
export const selectMessages = (state: RootState) =>
  state.messagingCentre.messages;

export const messagesCount = (state: RootState) =>
  state.messagingCentre.messagesCount;

export const selectHasNextMessages = (state: RootState) =>
  state.messagingCentre.hasNextMessages;

// unreadMessagesCount
export const selectUnreadMessagesCount = (state: RootState) =>
  state.messagingCentre.unreadCount;

/* --- ACTIONS --- */
export const { setMessages, setMessageUsers } = messagingCentreSlice.actions;

/* --- THUNKS --- */
// messageUsers
export const messageUsersAction = createAsyncThunk(
  `${messagingCentreSlice.name}/messageUsers`,
  async ({ name, limit }: MessageUsersProps) => {
    const response = await getMessageUsers({ name, limit });
    return response.data;
  }
);

// availableUsers
export const availableUsersAction = createAsyncThunk(
  `${messagingCentreSlice.name}/availableUsers`,
  async ({ name, limit }: MessageUsersProps) => {
    const response = await getAvailableUsers({ name, limit });
    return response.data;
  }
);

// blockedUsers
export const blockUserAction = createAsyncThunk(
  `${messagingCentreSlice.name}/blockUser`,
  async ({ organisationId, isBlocked }: BlockUserProps) => {
    const response = await blockUser({ organisationId, isBlocked });
    return response.data;
  }
);

// searchMessages
export const searchMessagesAction = createAsyncThunk(
  `${messagingCentreSlice.name}/searchMessages`,
  async ({ organisationId, limit }: SearchMessagesProps, { getState }) => {
    const response = await searchMessages({ organisationId, limit });
    return response.data;
  }
);

// sendMessage
export const sendMessageAction = createAsyncThunk(
  `${messagingCentreSlice.name}/sendMessage`,
  async ({ text, organisationId }: SendMessageProps, thunkAPI) => {
    const response = await sendMessage({ text, organisationId });
    return response.data;
  }
);

// getUnreadMessagesCount
export const getUnreadMessagesCountAction = createAsyncThunk(
  `${messagingCentreSlice.name}/getUnreadMessagesCount`,
  async () => {
    const response = await getUnreadMessagesCount();
    return response.data;
  }
);

// readMessages
export const readMessagesAction = createAsyncThunk(
  `${messagingCentreSlice.name}/readMessages`,
  async ({ organisationId }: ReadMessagesProps) => {
    const response = await readMessages({ organisationId });
    return response.data;
  }
);

export default messagingCentreSlice.reducer;
