import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Cookie from 'js-cookie';
import Button from '../../atoms/Button';
import AddGroupEventToCalendarButton from '../../atoms/AddGroupEventToCalendarButton';
import VolunteerProfile from '../../components/VolunteerProfile';
import { SocialShareModal } from '../../atoms/SocialShareButton';
import { getUrlParam } from '../../utils/url';
import { toTitleCase } from '../../utils/text';
import { getErrorMessageFromResponse } from '../../utils/error';
import { formatDate } from '../../utils/time';

import useGetGroupEvents from '../../apiHooks/groupEvents/useGetGroupEvent';

import './group-event-details.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { CustomMasterclassTypography } from '../../styling/generalStyling';
import DOMPurify from 'dompurify';

const orgBaseUrl = process.env.REACT_APP_ORG_URL;

const GroupEventDetails = () => {
  const HtmlContent = (htmlContent: string) => {
    // Sanitize the HTML content before setting it
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);

    return (
      <div>
        {/* Using dangerouslySetInnerHTML to inject sanitized HTML */}
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      </div>
    );
  };
  const groupEventCode = getUrlParam('code') as string;
  const partnerId = getUrlParam('id') as string;
  const isLoggedIn = !!Cookie.get('accessTokenDB');
  const shouldRedirect = !isLoggedIn;
  const orgGroupDetailsUrl = partnerId
    ? `${orgBaseUrl}group-event-details?code=${groupEventCode}&id=${partnerId}`
    : `${orgBaseUrl}group-event-details?code=${groupEventCode}`;
  if (shouldRedirect) window.location.replace(orgGroupDetailsUrl);

  const [socialShareModalVisible, setSocialShareModalVisible] = useState(false);

  const { volunteerProfile: myVolunteerProfile, userLocation } =
    useSelectProfileData();
  const myVolunteerId = myVolunteerProfile?.id;
  const { timezone } = userLocation || {};
  const {
    groupEventData = [],
    hasFinishedLoadingGroupEvents,
    groupEventError,
  } = useGetGroupEvents({ code: groupEventCode });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groupEvent = groupEventData[0] || {};

  const {
    title,
    duration,
    interest,
    start_time = '',
    type: eventType = '',
    volunteer: volunteerProfile,
    description,
  } = groupEvent;
  const subject = title || '';
  const subTitle = interest?.name || '';
  const isMe = myVolunteerId === volunteerProfile?.id;

  const formattedDate = formatDate(start_time, timezone, duration || 60);

  useEffect(() => {
    if (hasFinishedLoadingGroupEvents && !groupEvent.id && !groupEventError)
      window.location.replace(orgGroupDetailsUrl);
  }, [
    hasFinishedLoadingGroupEvents,
    groupEvent,
    groupEventError,
    orgGroupDetailsUrl,
  ]);

  return hasFinishedLoadingGroupEvents && groupEvent.id && !groupEventError ? (
    <>
      <Helmet>
        <div className="group-event-details__title">
          {toTitleCase(interest.name)} {toTitleCase(eventType)} - DigitalBoost
        </div>
      </Helmet>
      <div className="group-event-details__container">
        {socialShareModalVisible && (
          <div
            onClick={() => setSocialShareModalVisible(false)}
            className="group-event-details__modal-overlay"
          />
        )}
        <div className="group-event-details__card">
          <div className="group-event-details__header">
            <div className="group-event-details__header-text-container">
              <div className="group-event-details__title-container">
                <h1 className="group-event-details__title">
                  {toTitleCase(subject)}
                </h1>
                <CustomMasterclassTypography>
                  {eventType && eventType.toUpperCase()} ON{' '}
                  {subTitle.toUpperCase()}
                </CustomMasterclassTypography>
              </div>
              {formattedDate && (
                <div className="group-event-details__header-date-container">
                  <div className="group-event-details__header-date-wrapper">
                    <span className="group-event-details__header-time-text">
                      {formattedDate?.time?.hour},{' '}
                    </span>
                    <span className="group-event-details__header-date-text">
                      {formattedDate?.date?.day} {formattedDate?.date?.month}
                    </span>
                  </div>
                  <span className="group-event-details__header-timezone-text">
                    {formattedDate?.time?.timeZoneCode}
                  </span>
                </div>
              )}
            </div>
            <div className="group-event-details__header-buttons-container">
              <div className="group-event-details__header-buttons-subcontainer">
                <div className="group-event-details__social-share-container">
                  <Button
                    buttonType="secondary"
                    className="group-event-details__social-share-button"
                    onClickFunction={() =>
                      setSocialShareModalVisible(!socialShareModalVisible)
                    }
                  >
                    <img src="/share.png" alt="share" />
                    <span>Share</span>
                  </Button>
                  {socialShareModalVisible && (
                    <SocialShareModal
                      eventType={eventType}
                      subject={subject}
                      name={`${volunteerProfile.first_name} ${
                        volunteerProfile.last_name
                          ? volunteerProfile.last_name
                          : ''
                      }`}
                      companyName={volunteerProfile.employer}
                      startTime={groupEvent.start_time}
                      onCloseClick={() => setSocialShareModalVisible(false)}
                      className="group-event-details__social-share-modal"
                    />
                  )}
                </div>
                {hasFinishedLoadingGroupEvents && formattedDate && (
                  <AddGroupEventToCalendarButton
                    eventInfo={groupEvent}
                    timeZoneString={timezone || ''}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="group-event-details__body">
            <div className="group-event-details__description-container">
              <h3>About this {toTitleCase(eventType)}</h3>
              <p>{HtmlContent(description ? description : '')}</p>
              <div className="group-event-details__date-location-container">
                {formattedDate && (
                  <>
                    <span className="group-event-details__date-title">
                      Date and Time
                    </span>
                    <span className="group-event-details__date-text">
                      {formattedDate?.time?.day}, {formattedDate?.date?.day}{' '}
                      {formattedDate?.date?.month}
                    </span>
                    <span className="group-event-details__start-time-text">
                      {formattedDate?.time?.hour} -{' '}
                      {formattedDate?.endDate?.time?.hour},{' '}
                      {formattedDate?.time?.timeZoneCode ||
                        formattedDate?.time?.timeZone}
                    </span>
                  </>
                )}

                <span className="group-event-details__location-title">
                  Location
                </span>
                <span className="group-event-details__location-text">
                  Online Event
                </span>
              </div>
            </div>
            <div className="group-event-details__volunteer-profile-container">
              <VolunteerProfile
                volunteerProfile={volunteerProfile}
                compact
                hideSkills
                isMe={isMe}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="group-event-details__loading-container">
      {groupEventError && !shouldRedirect ? (
        <h1 className="group-event-details__error">
          {' '}
          {getErrorMessageFromResponse(groupEventError)}
        </h1>
      ) : (
        <img
          className="group-event-details__loading-spinner"
          src="/loading.gif"
          alt="loading"
        />
      )}
    </div>
  );
};

export default GroupEventDetails;
